<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-layout row justify-space-between align-center >
          <v-flex xs12 sm12 md12 lg12>
            <span class="heading">Assigned Operations</span>
          </v-flex>
        
            <!-- <v-flex xs12 sm6 md6 lg5 pl-md-2 pl-lg-2 pl-sm-2>
              <v-text-field
                outlined
                hide-details
                dense
                placeholder="Type To Search"
                 style="font-family: sofiaProRegular;"
                v-model="keyword"
              >
              </v-text-field>
            </v-flex> -->
            </v-layout>
    
          <v-flex xs12 py-4 v-if="items && items.length > 0">
           
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-card>
                  <div style="overflow-y: auto; overflow-x: auto;">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left tablehead">Assigned Date</th>
                            <th class="text-left tablehead">Situation</th>
                            <th class="text-left tablehead">Rescuer</th>
                            <th class="text-left tablehead">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in items" :key="index">
                            <td style="white-space: nowrap;">
                              <span v-if="item && item.release && item.release.releasedAt">
                                {{ formatDate(item.release.releasedAt) }}
                              </span>
                              <span v-else>-</span>
                            </td>
                            <td style="white-space: nowrap;">
                              {{ item.reportId.situation }}
                            </td>
                            <td style="white-space: nowrap;">
                              <span v-if="item && item.rescuer && item.rescuer.name">
                                {{ item.rescuer.name }}
                              </span>
                              <span v-else>-</span>
                            </td>
                            <td style="white-space: nowrap;">
                              <v-icon
                                small
                                color="success"
                                class="ml-4"
                                @click.stop="openviewdetails(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-card>
                
                
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="items && items.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              circle
              color="#FF6907"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout
          wrap
          justify-center
          align-center
         
        >
          <v-flex xs12 class="text-center">
            <span class="nodata">No Data Found !</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
          <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 20,
      keyword: "",
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      items: [],
      status: "",
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
    },
  },
  methods: {
    openviewdetails(item) {
      this.$router.push({
        path: "/assignedoperationsview",
        query: {
          id: item._id,
        },
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/operation/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          status: "Assigned",
          keyword: this.keyword,
          count:this.count,
          page:this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              this.status = response.data.status;
              this.Pagelength = response.data.pages;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return "-";
      const [year, month, day] = date.slice(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
  },
};
</script>
          <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>